import {useMemo} from 'react';
import {useCurrentSlug} from '../useCurrentSlug';
import {useEnhanced} from '../useEnhanced';
import {useIsCoverageToolAvailable} from '../useIsCoverageToolAvailable';
import {useRenderInIFrame} from '../useRenderInIFrame';
import {useShowAI} from './useShowAI';
import {useShowDosageSection} from './useShowDosageSection';
import {useShowEnhancedSection} from './useShowEnhancedSection';
import {useShowFASection} from './useShowFASection';
import {useShowPASection} from './useShowPASection';
import {PATIENT_EDUCATION_SECTION} from '@/constants/flags';
import * as FLAGS from '@/constants/flags';
import {FULL_SECTION_IDS} from '@/constants/sectionIDs';
import {useFlag} from '@/context';

export function useSectionsStatus() {
  const {setId} = useCurrentSlug();
  const pdpIsEmbedded = useRenderInIFrame();
  const {isEnhanced} = useEnhanced(setId);

  const showPA = useShowPASection(setId);

  const showPE = useShowEnhancedSection({
    sectionId: FULL_SECTION_IDS.patientEducation,
    flagName: PATIENT_EDUCATION_SECTION,
  });

  const financialAssistanceFlags = useShowFASection(setId);

  const showDosage = useShowDosageSection();

  const showFAQS =
    useShowEnhancedSection({
      sectionId: FULL_SECTION_IDS.peopleAlsoAsk,
      flagName: FLAGS.PEOPLE_ALSO_ASK_SECTION,
      checkAvailableResources: false,
    }) && !pdpIsEmbedded;
  const showFAQsNonEnhanced = useFlag(FLAGS.PEOPLE_ALSO_ASK_SECTION);

  const showPubMed = useShowEnhancedSection({
    sectionId: FULL_SECTION_IDS.pubMed,
    flagName: FLAGS.PUB_MED_ARTICLES,
    checkAvailableResources: false,
  });
  const showPubMedNonEnhanced = useFlag(FLAGS.PUB_MED_ARTICLES);

  const showLabel =
    useShowEnhancedSection({
      sectionId: FULL_SECTION_IDS.drugLabel,
      checkAvailableResources: false,
    }) && !pdpIsEmbedded;

  const coverageFlags = useIsCoverageToolAvailable();

  const aiFlags = useShowAI();

  const sections = useMemo(
    () => ({
      showDosage, // dosages and administration
      showPA, // prior authorization
      showPE, // patient education
      showFAQS: isEnhanced ? showFAQS : showFAQsNonEnhanced, // people also ask
      showPubMed: isEnhanced ? showPubMed : showPubMedNonEnhanced, // pub med
      showLabel: isEnhanced ? showLabel : true, // drug label
      ...coverageFlags,
      ...aiFlags,
      ...financialAssistanceFlags,
    }),
    [
      showDosage,
      financialAssistanceFlags,
      aiFlags,
      showPA,
      showPE,
      showFAQS,
      showFAQsNonEnhanced,
      showPubMed,
      showPubMedNonEnhanced,
      showLabel,
      coverageFlags,
      isEnhanced,
    ],
  );

  return sections;
}
