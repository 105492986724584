import {useEnhanced} from '../useEnhanced';
import {useShowEnhancedSection} from './useShowEnhancedSection';
import * as FLAGS from '@/constants/flags';
import {FULL_SECTION_IDS} from '@/constants/sectionIDs';
import {useFlag} from '@/context';

export const useShowFASection = (setId: string) => {
  const {
    enhancedContent,
    isEnhanced,
    disableBrandFinancialAssistancePrograms: disabledByEnhancedFlag,
    hasSanityFinancialAssistancePrograms,
  } = useEnhanced(setId);

  const {copaySavingsCardProgram, patientAssistanceProgram} =
    enhancedContent ?? {};

  const showOldFA = useShowEnhancedSection({
    sectionId: FULL_SECTION_IDS.financialAssistance,
    flagName: FLAGS.FINANCIAL_ASSISTANCE_SUPPORT_SECTION,
    useEnhancedFlag: false,
  });

  const showFinderFA = useFlag(FLAGS.AFFORDABILITY_ON_PDP);
  const showFinancialAssistancePages = useFlag(
    FLAGS.FINANCIAL_ASSISTANCE_PAGES,
  );

  // if there are no programs available, show the generic financial assistance support section
  const programsAvailable = !!(
    (copaySavingsCardProgram || patientAssistanceProgram) //NOSONAR
  );

  const hideFinancialAssistanceLink =
    showFinancialAssistancePages && hasSanityFinancialAssistancePrograms;

  const showFASectionLinks =
    !disabledByEnhancedFlag &&
    ((showFinderFA && programsAvailable) || showOldFA) &&
    !hideFinancialAssistanceLink;

  const sectionIsNull =
    !isEnhanced || disabledByEnhancedFlag;


  const showFinancialAssistanceSection =
    showFinderFA && (!!copaySavingsCardProgram || !!patientAssistanceProgram);

  return {
    showFASectionLinks,
    showFinancialAssistanceSection,
    showOldFA,
    sectionIsNull,
    hideFinancialAssistanceLink,
  };
};
