import {
  createFallbackableCache,
  createMemoryCache,
  createNullCache,
} from '@algolia/client-common';
import {algoliasearch} from 'algoliasearch';
import {
  ALGOLIA_APP_ID,
  ALGOLIA_SEARCH_API_KEY,
  DEV_ALGOLIA_APP_ID,
  DEV_ALGOLIA_SEARCH_API_KEY,
} from '@/constants/global';

export const searchClient = algoliasearch(
  ALGOLIA_APP_ID,
  ALGOLIA_SEARCH_API_KEY,
  {
    responsesCache: createFallbackableCache({
      caches: [
        // fallbackable cache will try each caching mechanism in the given order
        createMemoryCache(),
        createNullCache(), // null cache is a no-op
      ],
    }),
    hostsCache: createMemoryCache(),
  },
);

export const devClient = algoliasearch(
  DEV_ALGOLIA_APP_ID,
  DEV_ALGOLIA_SEARCH_API_KEY,
  {
    responsesCache: createFallbackableCache({
      caches: [
        // fallbackable cache will try each caching mechanism in the given order
        createMemoryCache(),
        createNullCache(), // null cache is a no-op
      ],
    }),
    hostsCache: createMemoryCache(),
  },
);
